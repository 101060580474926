body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: #f8f8f8;
  font-size: 14px;
}

@font-face {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  src: url("./assets/fonts/Inter-ExtraBold.woff2") format("woff2"), url("./assets/fonts/Inter-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  src: url("./assets/fonts/Inter-Black.woff2") format("woff2"), url("./assets/fonts/Inter-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  src: url("./assets/fonts/Inter-Bold.woff2") format("woff2"), url("./assets/fonts/Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  src: url("./assets/fonts/Inter-ExtraLight.woff2") format("woff2"), url("./assets/fonts/Inter-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  src: url("./assets/fonts/Inter-Regular.woff2") format("woff2"), url("./assets/fonts/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  src: url("./assets/fonts/Inter-Light.woff2") format("woff2"), url("./assets/fonts/Inter-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  src: url("./assets/fonts/Inter-Medium.woff2") format("woff2"), url("./assets/fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  src: url("./assets/fonts/Inter-SemiBold.woff2") format("woff2"), url("./assets/fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  src: url("./assets/fonts/Inter-Thin.woff2") format("woff2"), url("./assets/fonts/Inter-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

em {
  font-style: normal;
}

.header-right .icons > a {
  padding: 0 0.3125rem;
  color: #424242;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
}
.logo-servicecpq {
}

.logo-servicecpq .cls-1 {
  fill: #ffffff;
}

.logo-servicecpq .cls-2 {
  fill: #7d35ed;
}

.logo-servicecpq .cls-3 {
  mix-blend-mode: multiply;
}

.logo-servicecpq .cls-4 {
  isolation: isolate;
}

h5 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  src: url("./assets/fonts/Inter-SemiBold.woff2") format("woff2"), url("./assets/fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sideBar1 {
  position: relative;
  overflow: hidden;
  width: auto;
  height: 100%;
}

.sideBar2 {
  overflow: hidden;
  width: auto;
  height: 100%;
}

.sideBar3 {
  background: transparent;
  width: 5px;
  position: absolute;
  top: 0px;
  opacity: 0.4;
  display: none;
  border-radius: 7px;
  z-index: 99;
  right: 1px;
  height: 2680.66px;
}

.sideBar4 {
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0px;
  display: none;
  border-radius: 7px;
  background: rgb(51, 51, 51);
  opacity: 0.2;
  z-index: 90;
  right: 1px;
}

.header-right .icons {
  float: left;
  margin: 0;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  /* border-left: 1px solid #cfcfcf; */
  padding: 0 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: #424242;
}

.header-right .icons.show {
}

.header-right .icons.show a i {
  color: #fff;
}

.header-li-text {
  line-height: 30px;
}

.gradient-2 {
  right: 26px !important;
  top: -8px !important;
}

.gradient-1 {
  right: 11px !important;
  top: -8px !important;
}

.header-right .icons .user-img img {
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  box-shadow: none;
  margin-bottom: 5px;
}

.header-right .icons .user-img .activity {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  border: 3px solid #fff;
  bottom: 0.4rem;
  right: 0rem;
  padding: 0;
  top: 10px;
  /* left: 5px; */
  right: 4px;
}

/* .header-right .icons.show a .li-contant {
  color: #fff;
}

.header-right .icons.show .li-contant {
  color: #fff;
} */

.form-control {
  font-size: 12px;
  color: #616161;
}

.search-form-control {
  border: none;
  font-weight: 500;
}

.bg-transparent {
  color: #616161;
  line-height: 0;
  font-size: 18px !important;
}

::-webkit-input-placeholder {
  color: #616161 !important;
}

.activity-div {
}

.text-dark-black {
  color: #231f20;
}

.text-grey {
  color: #76838f !important;
}

.bg-light-green {
  background: #f3fff5;
}

.bg-light-grey {
  background: #efefef;
}

.text-dark {
  color: #646464;
}

.text-light {
  color: #872ff7 !important;
}

.text-light-dark {
  color: #58595b;
}

.text-violet {
  color: #872ff7 !important;
}

.bg-violet {
  background-color: #872ff7;
}

.bg-light-pink {
  background: #f8f3ff;
}

.bg-primary {
  background: #872ff7 !important;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.overflow-hidden {
  overflow: hidden !important;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-21 {
  font-size: 21px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-36 {
  font-size: 36px !important;
}

.font-size-65 {
  font-size: 65px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.white-space {
  white-space: pre;
}

.modal-xl {
  max-width: 1266px;
}

.text-underline-offset {
  text-underline-offset: 5px;
}

.text-decoration-line {
  text-decoration-line: underline;
}

.custom-checkbox {
}

.custom-checkbox label {
  margin-bottom: 0 !important;
}

.modal-content {
  background: #fff;
}

.btn {
  padding: 8px 25px;
  border-radius: 6px !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #872ff7 !important;
}

a.remove-button:hover,
a.remove-button:focus {
  color: #fff !important;
}

.align-itemsstart {
  align-items: flex-start !important;
}

.home-icon {
  font-size: 18px !important;
  line-height: 64px;
  margin-top: -3px;
  margin-right: 5px;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{left: 627px !important;} */
.Accordion-div {
  margin-bottom: 15px;
  border-radius: 5px !important;
  overflow: hidden;
}

.Accordion-div .Accordion-btn {
  background: #ecfffe;
  color: #00b5aa;
  height: 50px !important;
  min-height: 50px !important;
  border: 1px solid;
  border-radius: 5px;
}

.Accordion-div .Accordion-btn2 {
  background: #fcebff;
  color: #750c84;
  height: 50px !important;
  min-height: 50px !important;
  border: 1px solid;
  border-radius: 5px;
}

.Accordion-div .Accordion-btn3 {
  background: #e3f5fe;
  color: #00354f;
  height: 50px !important;
  min-height: 50px !important;
  border: 1px solid;
  border-radius: 5px;
}

.alert-messges {
  border: 1px solid #872ff7;
  background-color: #f8f9ff !important;
  color: #872ff7 !important;
  font-size: 12px;
  border-radius: 10px;
  padding: 5px 10px;
}

.hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}

.Mui-selected {
  color: #872ff7 !important;
}

.css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 2px;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #872ff7 !important;
}

button:focus {
  outline: none;
}

.Add-new-segment-div {
  border: dashed 2px #872ff7;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 40px;
  height: 44px;
  line-height: 46px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  font-size: 14px;
}

.add-new-recod {
  border: dashed 1px #872ff7;
  width: 100%;
  display: flex;
  height: 275px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #f2f1fc;
  border-radius: 10px;
}

.cloudupload {
  font-size: 60px;
  color: #c4c9f4;
}

.border-light {
  border: 1px solid #a2a0e9 !important;
}

.add-new-recod .iciELI {
  border: none;
  background: #872ff7;
  color: #fff !important;
  padding: 7px 25px;
  height: auto;
}

.add-new-recod .ebdQkY > span {
  font-size: 12px;
  color: #fff;
}

.recent-div {
  background: #fbfbfb;
  border-top: 1px solid #f4f4f4;
}

.recent-items {
  border: 1px solid #919ca5;
  padding: 10px;
  border-radius: 5px;
}

.recent-items .custom-checkbox {
}

.recent-items .custom-checkbox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0;
}

.recent-items .custom-checkbox label {
  margin-right: 0 !important;
}

.recent-items .css-i4bv87-MuiSvgIcon-root {
  font-size: 20px;
}

.border {
  border: 1px solid #cfcece !important;
}

.border-blue {
  border: 1px solid #872ff7;
}

.css-baznur-MuiDataGrid-root .MuiDataGrid-cell {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.header-bottom {
  height: 5rem;
  z-index: 1;
  position: relative;
  padding: 0 0.9375rem;
  background: #fff;
  margin-left: 15.1875rem;
  transition: all 0.2s ease;
}

[data-sidebar-style="full"] .headerbottom,
[data-sidebar-style="overlay"] .header {
  width: calc(100% - 15.1875rem);
}

.nav-header .brand-logo a {
  display: block;
  padding: 15px;
  color: #424242;
}

.headerbottom {
  height: 60px;
  position: fixed;
  padding: 0 0.9375rem;
  background: #fff;
  margin-left: 15.1875rem;
  transition: all 0.2s ease;
  border-bottom: 1px solid #eeeeee;
  top: 80px;
  z-index: 1;
}

.header-icon {
  color: #fff;
}
.header-left {
  float: left;
  margin-left: 0;
  height: 100%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .headerbottom {
  margin-left: 3.75rem;
  width: calc(100% - 3.75rem);
}

[data-nav-headerbg="color_1"] .nav-headerrepairbuilder {
  background-color: #fff;
}

.nav-headerrepairbuilder {
  height: 60px;
  width: 15.1875rem;
  display: inline-block;
  text-align: left;
  position: fixed;
  left: 0;
  top: 80px;
  background: #111b27;
  box-shadow: 0 1px 10px rgb(0 0 0 / 15%);
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  padding: 15px;
  /* border-right: 1px solid #EEEEEE; */
  box-shadow: none;
  border-bottom: 1px solid #eeeeee;
  z-index: 1;
}

.content-body {
  margin-left: 3.1875rem;
  z-index: 0;
  margin-top: 65px;
}

[data-nav-headerbg="color_1"] .nav-header {
  background-color: #000000;
}

.nav-header {
  height: 5rem;
  width: 15.1875rem;
  display: inline-block;
  text-align: left;
  position: fixed;
  left: 0;
  top: 0;
  background: #000000;
  /* border-right: 1px solid #EEEEEE; */
  transition: all 0.2s ease;
  box-shadow: none !important;
  border-bottom: 1px solid #eeeeee;
  z-index: 1;
}

.header {
  height: 5rem;
  width: calc(100% - 15.1875rem);
  z-index: 2;
  position: fixed;
  padding: 1rem 0.9375rem;
  background: #fff;
  margin-left: 15.1875rem;
  transition: all 0.2s ease;
  border-bottom: 1px solid #eeeeee;
  top: 0;
}

[data-headerbg="color_1"] .headerbottom {
  background-color: #fff;
}

.main-menu .fa-2x {
  font-size: 2em;
}

.main-menu .fa {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}

.main-menu:hover,
nav.main-menu.expanded {
  width: 265px;
  overflow: visible;
}

.main-menu {
  background: #ffffff;
  border-right: 1px solid #eeeeee;
  position: fixed;
  top: 81px;
  bottom: 0;
  /* height: 100%; */
  left: 0;
  width: 60px;
  overflow: hidden;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
}

.main-menu > ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 265px;
}

.main-menu li:hover > a,
nav.main-menu li.active > a span {
  color: #872ff7 !important;
}

.main-menu li:hover > a,
nav.main-menu li.hover > a span {
  color: #872ff7 !important;
}

.main-menu li:hover > a span {
  color: #872ff7 !important;
}

.main-menu > ul > li > ul > li a {
  padding-left: 11px;
  margin: 4px 0;
}

.main-menu ul li ul li.active a {
  padding-left: 15px;
}

.main-menu li > a i {
  color: #616161;
}

.main-menu li > a {
  position: relative;
  display: block;
  border-collapse: collapse;
  border-spacing: 0;
  color: #000;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  /* -webkit-transition: all .1s linear;
  transition: all .1s linear; */
}

.main-menu > ul > li > a.active {
  color: #872ff7;
  background-color: #872ff715;
}

.main-menu > ul > li > ul > li > a.active {
  color: #872ff7;
  background-color: #872ff730;
}

.main-menu li > a.active svg {
  fill: #872ff7;
}

.main-menu li > a.active .nav-text {
}

.main-menu li > a.active .nav-text span {
  color: #872ff7 !important;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 170px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.main-menu > ul.logout {
  left: 0;
  bottom: 0;
  height: 100%;
  background: #e5e5e5;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.main-menu > ul > li,
.main-menu > ul > li:active {
  padding: 2px 0;
}

.main-menu > ul > li:hover > a,
nav.main-menu > ul > li.active > a {
  color: #872ff7;
  background-color: #872ff715;
  /* margin: 4px 0; */
}

.main-menu > ul > li > ul > li:hover > a,
nav.main-menu > ul > li > ul > li.active > a {
  color: #872ff7;
  background-color: #872ff730;
  margin: 4px 0;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: #fff;
  background-color: #872ff7;
  margin: 4px 0;
}

.main-menu:hover li ul li a {
  padding-left: 15px;
}

.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  src: local("Titillium WebLight"), local("TitilliumWeb-Light"),
    url(http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff) format("woff");
}

.headerbottom .header-content {
  height: 100%;
}

.headerbottom .header-content ul {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.headerbottom .header-content ul li {
  display: inline-block;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  padding: 0 5px;
}

.machine-drop {
  border: 1px solid #d6daff;
  padding-left: 0px;
  border-radius: 4px;
}

.machine-drop .label-div {
  padding: 2px 5px;
}

.machine-drop .css-e363es-MuiFormControl-root {
  width: 100%;
  margin: 0;
}

.machine-drop .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 2px 10px;
  font-size: 14px;
  border-left: 1px solid #e5e5e5;
  border-radius: 0;
}

.machine-drop fieldset {
  border: none;
}

.more-btn {
  position: relative;
}

.a-btn {
  background-color: #0984e3;
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 100%;
  color: #fff;
  display: flex;
  left: -60px;
  top: -2px;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
}

.b-btn {
  background-color: #00b894;
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 100%;
  color: #fff;
  display: flex;
  left: -44px;
  top: -2px;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
}

.c-btn {
  background-color: #e17055;
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 100%;
  color: #fff;
  display: flex;
  left: -27px;
  top: -2px;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
}

.main-menu .span-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}

.main-menu .span-icon svg {
  width: 15px;
  fill: #00000099;
}

/* To resize few sidebar icons - Start */
.main-menu .span-icon .tempRepair {
  width: 18px !important;
}

.main-menu .span-icon .tempTemplate {
  height: 18px !important;
}

.main-menu .span-icon .tempSolutions {
  width: 13px !important;
}
/* To resize few sidebar icons - End */

.main-menu li.active > a svg {
  fill: #872ff7;
}

.main-menu li:hover > a svg {
  fill: #872ff7;
}

.sub-li {
  position: relative !important;
}

.sub-li:last-child::before {
  border: none;
}

.main-menu:hover .sub-li:before {
  border-left: none;
}

.metismenu .has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  -webkit-transform-origin: top;
  border-color: initial;
  border-style: solid;
  border-width: 1px 0 0 1px;
  content: "";
  height: 0.5em;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: rotate(137deg) translateY(-50%);
  transform-origin: top;
  transition: all 0.3s ease-out;
  width: 0.5em;
}

.custom-tabs {
  padding: 0 15px;
}

.custom-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  border: 1px solid #e5e5e5;
}

.custom-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: #872ff7;
  color: #fff !important;
}

.custom-tabs .css-1aquho2-MuiTabs-indicator {
  background-color: none;
}

.custom-tabs .css-13xfq8m-MuiTabPanel-root {
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
}

.cursor {
  cursor: pointer;
}

.tableheader {
}

.tableheader .accordion {
  width: 100%;
  margin: 30px auto 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 0 0 10px 10px !important;
  overflow: hidden;
}

.tableheader .accordion .link {
  cursor: pointer;
  display: inline-block;
  color: #4d4d4d;
  font-size: 12px;
  position: relative;
  transition: all 0.4s ease;
  border: 1px solid #7d8adf;
  padding: 3px 15px;
  background: #f8f9fd;
  border-radius: 8px;
  margin: 10px;
}

.tableheader .accordion li {
  position: relative;
  background: #fff;
}

.tableheader .accordion li:last-child {
  margin-bottom: 0 !important;
}

.tableheader .accordion li:first-child {
  background-color: #fafafa !important;
}

.tableheader .accordion .lastOption {
  background-color: #fafafa !important;
}

.tableheader .accordion .lastOption:hover {
  color: #000 !important;
}

.tableheader .accordion .result:hover {
  background-color: transparent !important;
  color: #000 !important;
}

.tableheader .submenu a:hover {
  background: #7482dd;
  color: #fff;
}

.tableheader .accordion li .right-btn-box {
  position: absolute;
  right: 0;
  top: 0;
}

.tableheader .accordion li:last-child .link {
}

.tableheader .accordion li i {
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: #595959;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.tableheader .accordion li i.fa-chevron-down {
  right: 12px;
  left: auto;
  font-size: 8px;
  margin-left: 10px;
}

.tableheader .accordion li.open .link {
  color: #000;
}

.tableheader .accordion li.open i {
  color: #000;
}

.tableheader .accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

/**
 * Submenu
 -----------------------------*/

.tableheader .submenu {
  display: none;
  background: #ffffff;
  font-size: 12px;
  border-top: 1px solid #eee;
}

.tableheader .submenu li {
  border-bottom: 1px solid #f7f7f7;
}

.tableheader .submenu a {
  display: block;
  text-decoration: none;
  color: #000000;
  padding: 12px;
  padding-left: 15px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.tableheader .submenu a:hover {
  background: #7482dd;
  color: #fff;
}

/* .tableheader .bootstrap-select>.dropdown-toggle { position: unset;} */
.tableheader .dropup,
.dropright,
.dropdown,
.dropleft {
  position: unset;
}

.tableheader .maintableheader {
  position: relative;
}

.tableheader .inner {
  overflow-y: hidden !important;
  overflow: hidden;
}

.tableheader .bootstrap-select > .dropdown-toggle {
  padding: 3px 15px;
  background: transparent;
  color: #4d4d4d !important;
  font-size: 12px;
}

.tableheader .bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: thin dotted #333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
  outline: none !important;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0;
  vertical-align: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  content: "\f078";
  font: normal normal normal 14px/1 FontAwesome;
  font-weight: 100;
  font-size: 10px;
  margin-right: 10px;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0;
  vertical-align: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  content: "\f078";
  font: normal normal normal 14px/1 FontAwesome;
  font-weight: 100;
  font-size: 10px;
  margin-right: 10px;
}

.customDropdown {
}

.customDropdown.dropdown {
  position: relative;
}

.customDropdown .dropdown-menu.show {
  min-width: 160px !important;
  top: 26px !important;
}

.customDropdown .dropdown-toggle {
  background: transparent;
  border: none;
  background-image: url(../src/assets/icons/svg/menu.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.customDropdown .btn {
  padding: 8px 8px;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #f3f3f9;
  border-color: #f3f3f9;
}

.dropdown-menu.show {
  transform: none !important;
  top: 60px !important;
  width: 100% !important;
  /* min-width: 360px !important; */
  min-width: 275px !important;
}

.custom-modal .dropdown-menu.show {
  transform: none !important;
  top: 60px !important;
  width: 100% !important;
  /* min-width: 360px !important; */
  min-width: 700px !important;
}

.dropdown-toggle {
}

.customselect .pmselect .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0;
  vertical-align: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  content: "\f078";
  font: normal normal normal 14px/1 FontAwesome;
  font-weight: 100;
  font-size: 10px;
  margin-right: 10px;
}

.customselect {
  /* border: 1px solid #872ff7 !important; */
  background: #f8f9fd !important;
  /* border-radius: 8px; */
  color: #4d4d4d !important;
  font-size: 12px !important;
  /* z-index: 2; */
}

.customselectPortfolio {
  border: 1px solid #ced4da !important;
  background: #f8f9fd;
  border-radius: 8px;
  color: #4d4d4d !important;
  font-size: 12px;
}

.internalexternaltabs {
  border: 1px solid #cfcece;
  width: -moz-fit-content;
  /* width: fit-content; */
  border-radius: 4px;
  /* border-bottom: 0; */
  justify-content: space-between;

  /* border-radius: 4px; */
}

.internalexternaltabs li a {
  border-radius: 0;
}

.internalexternaltabs li a.active {
  background: #872ff7 !important;
  color: #ffff;
}

.approvesearch {
  border: 1px solid #cacaca;
  border-radius: 5px;
  padding: 3px 0;
}

.approvesearch .form-control {
  background: transparent;
  border: none;
}

.approvers-div {
}

.approvers-div .img-box {
  background: #fafafa;
  width: 40px;
  height: 40px;
  border: 1px solid #ececec;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.approvers-div .img-box span {
}

.approvers-div .contant-div {
}

.customselect .css-1s2u09g-control {
  background: transparent;
  border: none;
  min-height: 20px !important;
  max-width: 120px !important;
  width: 130px !important;
}

.customselectPortfolio .css-1s2u09g-control {
  background: transparent;
  border: none;
  min-height: 40px !important;
  max-width: 120px !important;
  width: 130px !important;
}

.customselect .css-6j8wv5-Input {
  margin: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.customselect .css-tlfecz-indicatorContainer {
  padding: 0 5px 0 0 !important;
}

.customselect .css-1gtu0rj-indicatorContainer {
  padding: 0 !important;
}

.customselect .css-1pahdxg-control {
  background: transparent;
  border: none;
  min-height: 20px !important;
  box-shadow: none !important;
  max-width: 120px !important;
  width: 130px !important;
}

.customselectPortfolio .css-1pahdxg-control {
  background: transparent;
  border: none;
  min-height: 40px !important;
  box-shadow: none !important;
  max-width: 120px !important;
  width: 130px !important;
}

.customselect .css-1pahdxg-control:hover {
  border: none !important;
  box-shadow: none !important;
}

.customselect .css-1pndypt-Input {
  padding: 0 !important;
  margin: 0 !important;
}

.customselect .bootstrap-select > select {
  position: unset !important;
  margin: 0;
  display: unset !important;
}

.customselect .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: auto !important;
}

.customselect .tableid {
}

.customselect .tableid .dropdown-toggle::after {
  display: none !important;
}

.customselect .tableid .dropdown-toggle {
  padding-right: 0 !important;
  width: 90px !important;
}

.customselect .pmselect {
}

.customselect .pmselect .dropdown-toggle {
  width: 120px !important;
  border-left: 1px solid #e8ecff;
  border-radius: 0;
  padding-left: 5px;
  font-weight: 600;
}

.tableheader .dropdown-toggle:hover {
  border-color: transparent !important;
}

.tableheader .dropdown-toggle:focus {
  border-color: transparent !important;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: transparent !important;
  border-color: transparent !important;
}

.icon-defold {
  position: relative;
}

.icon-defold .css-1s2u09g-control {
  border: none;
  /* padding-left: 28px; */
}

.icon-defold .form-control {
  padding: 0;
  height: 40px;
  position: relative;
}

.icon-defold .css-1pahdxg-control {
  border: none;
  padding-left: 28px;
}

.search-icon1 {
  position: absolute;
  top: 12px;
  left: 27px;
}

.solution-table {
}

.solution-table .css-13xfq8m-MuiTabPanel-root {
  padding: 0px;
}

.searchIcon {
  position: absolute;
  top: 8px;
  left: 2px;
}

.custom-table {
}

.custom-table .rdt_TableHeadRow {
  background: #872ff7 !important;
  color: white !important;
}

.rdt_TableHeadRow {
  background: #872ff7 !important;
  color: white !important;
}

.rounded-pill {
  border-radius: 50px;
}

.date-box {
}

.date-box .MuiInputBase-input {
  font-size: 12px;
}

.form-control-date {
  border: 1px solid #cfcece !important;
  border-radius: 10px;
}

.date-box .css-1s2u09g-control {
  border: none !important;
  background: #f5f5f5;
  border-right: 1px solid #cfcece !important;
  width: 120px !important;
  font-size: 14px;
  border-radius: 10px 0px 0px 10px;
}

.date-box .css-1pahdxg-control {
  width: 120px !important;
  background: #f5f5f5;
  font-size: 14px;
  border: none !important;
  box-shadow: none;
  border-right: 1px solid #cfcece !important;
  border-radius: 10px 0px 0px 10px;
}

.form-control-date .css-1okebmr-indicatorSeparator {
  display: none;
}

.form-control-date .css-tlfecz-indicatorContainer {
  color: #535353 !important;
}

.date-box .form-controldate {
  min-height: 38px;
  height: 38px;
  border-radius: 10px;
  display: block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  padding: 0.475rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
}

.date-box .form-controldate::before {
  content: "\f073";
  font-family: "FontAwesome";
  right: 10px;
  position: absolute;
  top: 8px;
}

.date-box .form-control {
  border: none !important;
  min-height: 38px;
  height: 38px;
  border-radius: 0px 10px 10px 0px;
}

.date-box .MuiInput-underline:after {
  display: none;
}

.date-box .MuiInput-underline::before {
  display: none;
}

.date-box .form-control .css-1pahdxg-control:hover {
  border-color: transparent !important;
  box-shadow: none !important;
}

.date-box .form-control .css-1pahdxg-control:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.form-control {
  box-shadow: none;
  height: 38px;
}
.text-input {
  font-size: 17px !important;
  color: #872ff7 !important;
}

.text-input::placeholder {
  font-size: 13px;
  color: #00000020;
}

.css-1s2u09g-control {
  font-size: 12px;
}

.css-1pahdxg-control {
  font-size: 12px;
  border-color: #cfcece !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:hover {
  border-color: #cfcece !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:focus {
  border-color: #cfcece !important;
  box-shadow: none !important;
}

.css-2613qy-menu {
  font-size: 12px !important;
}

.dropdownBtnCustom {
  padding: 0;
  background: transparent;
}

.dropdown-toggle::after {
  content: "";
  display: none;
}

.customMenu .dropdown-menu.show {
  transform: none !important;
  top: 60px !important;
  right: 23px !important;
  min-width: auto !important;
  width: auto !important;
}

.custometable {
  overflow-x: unset;
}

.custometable thead {
  background-color: #872ff7;
  color: #fff;
}

.custometable .table td {
  border-color: rgb(213 213 213);
  border-right: 1px solid rgb(213 213 213);
}

.custometable .table td:last-child {
  border-right: 0;
}

.custometable .dropdown {
  position: relative;
}

.custometable .dropdown-menu.show {
  width: auto !important;
  top: 20px !important;
}

.custometable .dropdown-iconbtn {
  background: transparent;
  padding: 0 10px;
}

.custometable table td {
  position: relative;
}

.custometable table td input {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  border: none;
  padding: 10px;
  box-sizing: border-box;
}

.custometable table {
  margin-bottom: 0;
}

.custometable table .css-b62m3t-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.custometable table .css-1s2u09g-control {
  height: 100%;
  border: none;
  box-shadow: none;
}

.custometable table .css-1pahdxg-control {
  height: 100%;
  border: none;
  box-shadow: none;
}

.customTabPanel {
  padding: 10px !important;
}

.customFormControlLabel .MuiFormControlLabel-label {
  padding-left: 15px;
  font-weight: bold;
  color: black;
}

.customFormControlLabel label {
  padding-left: 15px;
  font-weight: bold;
  color: black;
}

.customFormControlLabel p {
  padding-left: 15px;
}

.option-box {
  background: #eae1ec;
  padding: 15px;
}

.option-box .header-box {
  padding: 15px 0;
  border-bottom: 1px solid #fff;
}

.option-box .header-box h6 {
}

.option-box .css-1s2u09g-control {
  border: none;
  border-radius: 10px;
  min-height: 42px;
}

.option-box .css-1pahdxg-control {
  border: none;
  box-shadow: none;
  border-radius: 10px;
  min-height: 42px;
}

.option-box .css-1pahdxg-control:hover {
  border: none;
}

.selectlable {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.border-none {
  border: none !important;
}

.contain-slider {
  margin: 0 auto;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}

.item {
  background-color: #fff;
  color: #000;
  height: 300px;
  border: 1px solid #872ff7;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.Choose-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
}

.contain-slider .owl-next {
  position: absolute;
  right: -35px;
  top: 120px;
}

.contain-slider .owl-next span {
  width: 30px;
  height: 30px;
  font-size: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contain-slider .owl-prev {
  position: absolute;
  left: -35px;
  top: 120px;
}

.contain-slider .owl-prev span {
  width: 30px;
  height: 30px;
  font-size: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contain-slider .owl-theme .owl-dots {
  display: none;
}

.css-w8dmq8 {
  font-size: 13px !important;
  color: #616161 !important;
}

.input-info-msg {
  font-size: 12px !important;
  color: #616161d1 !important;
}
.cursor-pointer {
  cursor: pointer;
}
/* .header-right .icons.show a .li-contant {
  color: #fff;
}

.header-right .icons.show .li-contant {
  color: #fff;
} */

.form-control {
  font-size: 0.75rem;
  color: #616161;
  min-height: 38px;
  height: 38px;
  border-radius: 4px;
  font-weight: 500;
}

.search-form-control {
  border: none;
  font-weight: 500;
}

.bg-transparent {
  color: #616161;
  line-height: 0;
  font-size: 18px !important;
}

::-webkit-input-placeholder {
  color: #616161 !important;
}

.activity-div {
}

.text-dark-black {
  color: #231f20;
}

.text-grey {
  color: #76838f !important;
}

.bg-red {
  background: #c14e56;
}

.bg-dark-green {
  background: #00b8b0;
}

.bg-green-light {
  background: #52b596;
}

.bg-yellow {
  background: #d8bd5f;
}

.bg-light-dark {
  background: #f3f4ff;
}

.bg-light-grey {
  background: #efefef;
}

.text-dark {
  color: #646464;
}

.text-light {
  color: #872ff7 !important;
}

.config-labels {
  color: #872ff7;
  background-color: #872ff740;
  padding-block: 5px;
}

.text-light-dark {
  color: #58595b;
}

.text-violet {
  color: #872ff7;
}

.text-red {
  color: #d8947c;
}

.bg-perpal {
  background: #872ff7;
}

.bg-green {
  background: #00b8b0;
}

.bg-secondary {
  background-color: #616161 !important;
}

.ligt-greey-bg {
  background-color: #f8f8f8;
}

.text-black {
  color: #000;
}

.bg-violet {
  background-color: #872ff7;
}

.bg-light-pink {
  background: #f8f3ff;
}

.bg-primary {
  background: #872ff7 !important;
}

.bg-light-blue {
  background: #f3f4fe;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-size-12 {
  font-size: 0.75rem !important;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px !important;
}

.white-space {
  white-space: pre;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  padding: 12px 10px !important;
}

.maltipaltablecustom {
}

.maltipaltablecustom .ReactTable .rt-thead.-header {
  background-color: #872ff7;
  color: #fff;
}

.maltipaltablecustom .ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 12px 5px;
}

.maltipaltablecustom .ReactTable.-striped .rt-tr.-odd {
  background: rgb(255 255 255);
}

.custom-accordion {
}

.custom-accordion .css-15v22id-MuiAccordionDetails-root {
  padding: 0;
}

.custom-accordion .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 53px;
}

.custom-accordion .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: 53px;
}

.custom-accordion .css-ravng7-MuiSvgIcon-root {
  color: #76838f;
}

.customActionWidth {
  min-width: 300px !important;
}

.panel-table {
  display: table;
}

.panel-table > .panel-heading {
  display: table-header-group;
  background: transparent;
}

.panel-table > .panel-body {
  display: table-row-group;
}

.panel-table > .panel-body:before,
.panel-table > .panel-body:after {
  content: none;
}

.panel-table > .panel-footer {
  display: table-footer-group;
  background: transparent;
}

.panel-table > div > .tr {
  display: table-row;
}

.panel-table > div:last-child > .tr:last-child > .td {
  border-bottom: none;
}

.panel-table .td {
  display: table-cell;
  padding: 15px;
  border: 1px solid #ddd;
  border-top: none;
  border-left: none;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panel-table .td:last-child {
  border-right: none;
}

.panel-table > .panel-heading > .tr > .td,
.panel-table > .panel-footer > .tr > .td {
  background-color: #f5f5f5;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.panel-table > .panel-heading > .tr > .td:first-child {
  border-radius: 4px 0 0 0;
}

.panel-table > .panel-heading > .tr > .td:last-child {
  border-radius: 0 4px 0 0;
}

.panel-table > .panel-footer > .tr > .td:first-child {
  border-radius: 0 0 0 4px;
}

.panel-table > .panel-footer > .tr > .td:last-child {
  border-radius: 0 0 4px 0;
}

.tableStatusSpan {
  display: block;
  background-color: #fef1ea;
  color: #f5884a;
  font-weight: bold;
  border-radius: 10px;
  margin-top: 10px;
  width: 90px;
  text-align: center;
  margin-bottom: 10px;
}

/* .customBuildBtnTableAction {
  width: 50%;
  color: white;
  background-color: #872ff7;
  height: 50%;
  text-align: center;
  border-radius: 5px;
} */

.customseleact {
}

.customseleact .MuiOutlinedInput-notchedOutline {
  border: none;
}

.customseleact .MuiOutlinedInput-input {
  padding: 0;
  padding-right: 32px;
  font-weight: 600;
  font-size: 14px;
}

.optionsbtn {
}

.optionsbtn .css-zddlty-MuiButtonBase-root-MuiButton-root {
  background: transparent;
  color: #76838f;
  font-size: 12px;
  font-weight: 400;
}

.optionsbtn .css-zddlty-MuiButtonBase-root-MuiButton-root:hover {
  background: transparent;
  color: #76838f;
  font-size: 12px;
  font-weight: 400;
}

.select-input {
  white-space: pre;
}

.select-input .css-1s2u09g-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background: #f5f5f5;
  border-right: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tlfecz-indicatorContainer {
  color: #a7a7a7 !important;
}

.customselect .css-tlfecz-indicatorContainer {
  color: #872ff7 !important;
}

.text-underline-offset {
  text-underline-offset: 5px;
}

.text-decoration-line {
  text-decoration-line: underline;
}

.css-6hp17o-MuiList-root-MuiMenu-list hr {
  display: none !important;
}

.customselectbtn {
}

.customselectbtn .css-1s2u09g-control {
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 50px;
  min-height: auto;
  background: #f0f9ff;
  padding: 0 10px;
}

.customselectbtn .css-1pahdxg-control {
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 50px;
  min-height: auto;
  background: #f0f9ff;
  padding: 0 10px;
}

.customselectbtn .css-6j8wv5-Input {
  margin: 0;
}

.customselectbtn .css-tlfecz-indicatorContainer {
  padding: 0;
}

.customselectbtn .css-319lph-ValueContainer {
  padding: 0;
}

.customselectbtn .css-1gtu0rj-indicatorContainer {
  padding: 0;
}

.custom-claimStatus-selectbtn {
}

.custom-claimStatus-selectbtn .css-1s2u09g-control {
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 50px;
  min-height: auto;
  background: #f0f9ff;
  padding: 0 10px;
}

.custom-claimStatus-selectbtn .css-1pahdxg-control {
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 50px;
  min-height: auto;
  background: #f0f9ff;
  padding: 0 14px;
}

.custom-claimStatus-selectbtn .css-6j8wv5-Input {
  margin: 0;
}

.custom-claimStatus-selectbtn .css-tlfecz-indicatorContainer {
  padding: 0;
}

.custom-claimStatus-selectbtn .css-319lph-ValueContainer {
  padding: 0;
}

.custom-claimStatus-selectbtn .css-1gtu0rj-indicatorContainer {
  padding: 0;
}

.rating-star {
  margin-left: 20px;
}

.rating-star .checked {
  color: orange;
}

.rating-star .fa-star {
  margin: 0 3px;
}

.tablerowmodal {
}

.tablerowmodal .modal-body {
  padding: 0;
}

.scrollbamenu {
  overflow-y: scroll;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  height: 100%;
}

#menu::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d6d6d6;
}

#ulstyle::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#lstyle::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d6d6d6;
}

.scrollbar {
  overflow-y: scroll;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  height: 65vh;
}

.scrollbar1 {
  overflow-y: scroll;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  background: #e2e2e2;
}

.customerlable {
  background: #e0f9f5;
  color: #00bb98;
  padding: 7px;
  border-radius: 20px;
  font-size: 14px;
}

.customwidth {
  width: fit-content;
}

.customwidth .css-1s2u09g-control {
  width: fit-content;
  border-radius: 10px;
}

.customwidth .css-1pahdxg-control {
  width: fit-content;
  border-radius: 10px;
}

.select-inputcostom {
}

.select-inputcostom .css-1s2u09g-control {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background: #f5f5f5;
  border-right: 0;
  width: max-content;
  border-radius: 10px;
}

.select-inputcostom .css-1pahdxg-control {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background: #f5f5f5;
  border-right: 0;
  width: max-content;
  border-radius: 10px;
}

.select-inputcostom1 .css-1s2u09g-control {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-left: 0;
  width: 160px;
  border-radius: 10px;
}

.select-inputcostom1 .css-1pahdxg-control {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-left: 0;
  width: 160px;
  border-radius: 10px;
}

.itemcustominput {
}

.itemcustominput .form-control {
  border-radius: 0 10px 10px 0;
}

.searcdropdown {
  padding: 2px 8px !important;
  border-radius: 40px !important;
  margin-right: 10px !important;
  text-transform: capitalize !important;
}

.progress {
  height: 4px;
  font-size: 0.75rem;
  background-color: #d4d4d4;
  border-radius: 0.25rem;
}

.progress-bar {
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #872ff7;
  transition: width 0.6s ease;
}

.p-t-200 {
  margin-top: 200px !important;
}

.p-t-150 {
  margin-top: 150px !important;
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 425px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.svg-div {
}

.svg-div svg {
  fill: #fff;
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

.custom-btn {
}

.custom-btn .btn {
  background-color: #872ff7;
  color: #ffffff;
}

.custom-btn .dropdown-menu.show {
  min-width: 176px !important;
}

.custom-portfolio-dropdown {
}

.custom-portfolio-dropdown .dropdown-menu.show {
  transform: none !important;
  top: 60px !important;
  width: auto !important;
  min-width: auto !important;
  right: 23px !important;
}

.Account-custom-tabs {
}

.Account-custom-tabs .sideways {
  display: block;
}

.Account-custom-tabs .tabs-left {
  border-bottom: none;
}

.Account-custom-tabs .tabs-left > li {
  float: none;
  margin: 0px;
}

.Account-custom-tabs .nav > li {
  position: relative;
  display: block;
}

.Account-custom-tabs .tabs-left > li.active > a,
.Account-custom-tabs .tabs-left > li.active > a:hover,
.Account-custom-tabs .tabs-left > li.active > a:focus {
  border-bottom-color: #ddd;
  border-right-color: transparent;
  border: none;
  margin: 0px;
}

.Account-custom-tabs .nav-tabs > li > a:hover {
  /* margin-right: 2px; */
  line-height: 1.42857143;
  /* border: 1px solid transparent; */
  /* border-radius: 4px 4px 0 0; */
}

.Account-custom-tabs .nav > li > .active {
  background: #862ff6;
  color: #fff;
}

.Account-custom-tabs .nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  background-color: #efefef;
  color: #a7a7a7;
  margin: 10px 0;
  border-radius: 6px;
}

.intexttab {
  border: none;
  display: block;
}

.intexttab li {
  display: table-cell;
  width: 1%;
}

.intexttab .btn {
  display: block;
}

.machine-droped {
  border: 1px solid rgb(207, 207, 207);
  padding-left: 0px;
  border-radius: 50px;
}

.machine-droped .label-div {
  padding: 2px 5px;
}

.machine-droped .css-e363es-MuiFormControl-root {
  width: 100%;
  margin: 0;
}

.machine-droped .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 2px 10px;
  font-size: 14px;
  border-left: 1px solid #e5e5e5;
  border-radius: 0;
}

.machine-droped fieldset {
  border: none;
}

.customselectbtn1 {
}

.customselectbtn1 .css-1s2u09g-control {
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 50px;
  min-height: auto;
  background: #dccb4c;
  padding: 0 10px;
}

.customselectbtn1 .css-1pahdxg-control {
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 50px;
  min-height: auto;
  background: #dccb4c;
  padding: 0 10px;
}

.customselectbtn1 .css-6j8wv5-Input {
  margin: 0;
}

.customselectbtn1 .css-tlfecz-indicatorContainer {
  padding: 0;
}

.customselectbtn1 .css-319lph-ValueContainer {
  padding: 0;
}

.customselectbtn1 .css-1gtu0rj-indicatorContainer {
  padding: 0;
}

.claim-order-version {
}

/* .claim-order-version .css-1insrsq-control { */
.claim-order-version .css-1s2u09g-control {
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 50px;
  min-height: auto;
  background: #dccb4c;
  padding: 0 10px;
}

.claim-order-version .css-1pahdxg-control {
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 50px;
  min-height: auto;
  background: #dccb4c;
  padding: 0 10px;
}

.claim-version-span {
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 50px;
  min-height: auto;
  background: #dccb4c;
  padding: 4px 12px;
  color: hsl(0, 0%, 20%);
}

.claim-order-version .css-6j8wv5-Input {
  margin: 0;
}

.claim-order-version .css-tlfecz-indicatorContainer {
  padding: 0;
}

.claim-order-version .css-319lph-ValueContainer {
  padding: 0;
}

.claim-order-version .css-1gtu0rj-indicatorContainer {
  padding: 0;
}

.cardradi {
  border-radius: 0 0 20px 20px;
  margin-top: -10px;
}

/* .Account-custom-tabs .tabs-left>li.active>a::after{content: "";
    position: absolute;
    top: 10px;
    right: -10px;
    border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  
  border-left: 10px solid #f90;
    display: block;
    width: 0;} */

.customselectsearch {
  position: relative;
}

.customselectmodelSerch {
  position: relative;
}

.customselectsearch .customselectsearch-list {
  width: 100%;
  position: absolute;
  z-index: 1;
}

.custommodelselectsearch {
  width: 100%;
  position: absolute;
  z-index: 1;
}

.customselectsearch .customselectsearch-list .list-group-item {
  padding: 5px 10px;
  cursor: pointer;
}

.custommodelselectsearch .list-group-item {
  padding: 5px 10px;
  cursor: pointer;
}

.style::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}

.style::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
  height: 2px;
}

.style::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

#style::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}

#style::-webkit-scrollbar {
  width: 1px;
  /* width: 2px; */
  background-color: transparent;
  height: 2px;
}

#style::-webkit-scrollbar-thumb {
  background-color: #000000;
}

#style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollbar-repair-autocomplete {
  overflow-y: scroll;
  width: 100%;
  margin: 0;
}

.scrollbar {
  height: 150px;
  overflow-y: scroll;
  width: 100%;
  margin: 0;
}

.style {
  max-height: 150px;
  /* overflow-y: scroll; */
  width: 100%;
  margin: 0;
  display: none;
  height: auto;
  overflow-y: auto;
}

.custom-input-sleact {
  background: #f8f9fd;
  border: none;
  border-radius: 0px 5px 5px 0;
  color: hsl(0deg 0% 19%);
  font-size: 12px;
  border-left: 1px solid #6f6f6f !important;
  padding-left: 5px;
}

.custom-input-sleact::-webkit-input-placeholder {
  color: #808080 !important;
}

.cMMpBL {
  font-size: 12px !important;
  color: #808080 !important;
  min-height: 44px !important;
}

/* .css-i4bv87-MuiSvgIcon-root {
  width: 0.7em !important;
  height: 0.7em !important;
} */
.MuiSvgIcon-fontSizeMedium {
  width: 0.7em !important;
  height: 0.7em !important;
}

.btn-svg {
}

.btn-svg svg {
  width: 16px;
  fill: #707070;
  stroke: #707070;
}

.tabledate-box {
}

.tabledate-box .form-controldate {
  border: none !important;
}
.input-fields .date-box .MuiInputBase-input {
  font-size: 17px;
  color: #872ff7;
  font-weight: 500;
}

.listcheckbox {
}

.listcheckbox input[type="checkbox"]:after {
  border: 1px solid #bfbfbf;
  border-radius: 50px;
}

.hours-div {
  padding: 0.375rem 0.75rem;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  white-space: pre !important;
}

.search-drop-div {
}

.search-drop-div.show {
  top: 60px !important;
}

.eLCUDv {
  min-width: auto !important;
}

.sc-iBkjds:last-child {
  min-width: 180px !important;
}

.row-svg-div {
}

.row-svg-div svg {
  font-size: 24px;
  width: 14px;
  fill: #706e6e;
}

.row-svg-div img {
  width: 14px;
}

.custom-align {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.btn-primary {
  color: #fff;
  background-color: #872ff7;
  border-color: #872ff7;
}

.btn-no-border {
  border: none;
  background-color: #fff;
  color: #872ff7 !important;
  cursor: pointer;
}

.btn-no-border:disabled {
  border: none;
  background-color: #fff;
  display: none;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #872ff7 !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #872ff7 !important;
}

.text-primary {
  color: #872ff7 !important;
}

.kiHXWb {
  border: dashed 2px #872ff7 !important;
}

.kiHXWb svg path {
  fill: #872ff7 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #872ff7 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #872ff7 !important;
}

.convert {
  font-size: 13px;
  color: #fff;
}

.customselect1 {
}

.customselect1 .css-14el2xx-placeholder {
  color: #fff !important;
}

.customselect1 .css-tlfecz-indicatorContainer {
  color: #fff !important;
}

.custommenu {
  display: block !important;
  padding: 8px !important;
}

.MuiMenu-list {
}

.MuiMenu-list li {
  display: block;
  padding: 8px;
}

.custom-pricing-table .rdt_TableHeadRow {
  background: #872ff7 !important;
  color: #fff;
}

.custom-pricing-table {
  width: 80%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.customselect1 .css-6j8wv5-Input {
  color: #fff !important;
}

.customselect1 .css-qc6sy-singleValue {
  color: #fff !important;
}

.customselect1 .css-tj5bde-Svg {
  color: #fff !important;
}

.cmFPbp {
  background: #872ff7 !important;
}

.doQoVi {
  overflow: hidden !important;
}

.custommenu {
  display: block !important;
  padding: 8px !important;
}

.MuiMenu-list {
}

.MuiMenu-list li {
  display: block;
  padding: 8px;
}

.maintableheader .css-ahj2mt-MuiTypography-root {
  font-size: 12px;
}

.tableheader .templateResultheading {
  width: 100%;
  margin: 30px auto 20px;
  border-radius: 10px 10px 10px 10px !important;
  overflow: hidden;
}

a.bg-primary:focus,
button.bg-primary:focus {
  background-color: #872ff7 !important;
}

a.bg-primary:hover,
button.bg-primary:hover {
  background-color: #4540f7 !important;
}

.search-dropdown-list .dropdown-item {
  padding: 10px 10px;
  border-bottom: 1px solid #edebeb78;
}

.search-dropdown-list .dropdown-last-item {
  padding: 7px 10px;
  border-bottom: none !important;
}

.search-dropdown-list .dropdown-item .span-icon {
  position: relative;
  display: table-cell;
  /* width: 60px; */
  /* height: 60px; */
  text-align: center;
  vertical-align: middle;
  /* font-size: 10px; */
}

.search-dropdown-list .dropdown-item .span-icon svg {
  width: 20px;
  fill: #872ff7;
}

.existing-template-div .active {
  background-color: #4540f7 !important;
}

.css-3e2aqh-MuiDataGrid-root .MuiDataGrid-columnHeaders {
  background-color: #872ff7 !important;
  color: #fff;
}

.item2 {
  background-color: #fff;
  color: #000;
  height: 400px;
  border: 1px solid #872ff7;
  padding: 0;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.Choose-btn2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-bg {
  background: transparent;
  border: none;
  background-image: url(../src/assets/icons/svg/jcb.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  height: 120px;
}

.custom-green-btn {
  position: absolute;
  background-color: #59ce9f !important;
  border-radius: 50px;
  bottom: 4px;
  right: 6px;
}

.item3 {
  background-color: #fff;
  color: #000;
  height: 220px;
  border: 1px solid #872ff7;
  padding: 30px;
  border-radius: 10px;
  overflow: hidden;
}

.Choose-btn3 {
  position: absolute;
  left: 0;
  right: 0;
  border-bottom-left-radius: 10px;
  bottom: 0;
  border-bottom-right-radius: 10px;
}

.searchtext .MuiInputBase-inputMultiline {
  height: 29px !important;
  resize: none;
  padding: 5px 5px 5px 29px !important;
}

.searchtext .modal-content {
  border: 1px solid #4540f7 !important;
}

.searchtext .MuiFormControl-root {
  width: 100% !important;
}

.searchtext .icon-search {
  position: absolute;
  left: 3px;
  top: 12px;
}

.searchtext .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  padding: 0 0 0 29px !important;
}

.searchtext .MuiInputBase-root {
  line-height: 1.9em;
  padding-left: 29px;
}

.invlaid-email-password {
  /* display: none; */
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #ff5e5e;
  font-weight: 500;
}

.bg-gray {
  background-color: #9e9e9e;
}

.bg-pink {
  background-color: #ff6493 !important;
}

/* .tab2 .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
  border: 1px solid #9E9E9E !important;
  margin-right: 17px !important;
  border-radius: 9px !important;
} */
.heading-tabs {
  text-transform: capitalize !important;
  border: 1px solid #9e9e9e !important;
  margin-right: 17px !important;
  border-radius: 6px !important;
  padding: 12px 10px !important;
  min-height: 32px !important;
  max-height: 32px;
}

.heading-tabs.Mui-selected {
  color: #872ff7 !important;
  border: 1px solid #673ab7 !important;
  border-bottom: 1px solid #673ab7 !important;
}

.tab2 .css-1ujnqem-MuiTabs-root {
  min-height: 48px !important;
}

/* .tab2 .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #872ff7 !important;
  border: 1px solid #673ab7 !important;
} */
.tab2 .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-height: 32px !important;
  max-height: 32px;
}

.tab2 .css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}

/* .hUvRIg {
  border-right: 1px solid rgba(0,0,0,.12);
}
.WMXoO{border-right: 1px solid rgba(0,0,0,.12);} */
/* .checkbox-custom2 .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #fff !important;
  border: 1px solid #00B8B0;
  border-radius: 8px !important;
  padding: 1px 1px 1px 1px;
  margin-right: 12px;
} */
.footer-bottom {
  position: fixed;
  bottom: 0;
  left: 57px;
  right: 0;
}

.padding-custom {
  padding: 15px 30px 100px 30px !important;
}

.search-absolute {
  position: absolute;
  right: 10px;
  top: 9px;
}

.profile-img {
  width: 100%;
  border: 1px solid #76838f;
  border-radius: 50%;
}

.input-fields ::-webkit-input-placeholder {
  color: #872ff7 !important;
  font-size: 17px !important;
}

.input-fields .form-control {
  font-size: 17px !important;
}

.search-icon-postn {
  position: absolute;
  top: 9px;
  left: 6px;
  z-index: 1;
}

.customize .custom-input-sleact {
  padding-left: 24px !important;
}

.input-fields .css-1s2u09g-control {
  border-radius: 10px;
}

.css-1q2h7u5 {
  padding: 12px 16px !important;
}

.height-66 {
  min-height: 66px !important;
}

.custom-tabs-div {
}

.custom-tabs-div span {
  display: none;
}

.custom-tabs-div button {
  padding: 10px 15px !important;
}

.custom-tabs-div button.Mui-selected {
  color: #872ff7 !important;
  border-bottom: 2px solid #872ff7 !important;
}

.rdt_TableRow {
  font-size: 12px !important;
  color: #808080 !important;
  min-height: 44px !important;
}

.rdt_TableHead {
  border-radius: 6px !important;
  overflow: hidden !important;
}

.more-icon-div {
}

.more-icon-div button {
  padding: 0 !important;
  font-size: 14px !important;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.dropdown-content-heading {
  padding: 0 10px !important;
}

.white-space-normal {
  white-space: normal !important;
}

.height-82 {
  height: 82px !important;
}

.border-radius-1 {
  border-radius: 1rem !important;
}

.custom-rdt_TableCell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: 8px;
  padding-right: 8px;
  word-break: break-word;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
  min-width: 100px;
}

.input-fields .form-control {
  color: #872ff7;
}

.border-primary {
  border: 1px solid #872ff7 !important;
}

.border-white {
  border: 1px solid #ffffff !important;
}

.border-radius-50 {
  border-radius: 50px !important;
}
.remove-button {
  width: 70px;
  display: flex;
  height: 26px !important;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-width: 1px;
  border-radius: 4px !important;
}

.search-box-options {
  font-size: 12px;
  border-bottom: "1px solid #00000025";
}

.service-button {
  background-color: #872ff7;
  cursor: pointer;
  color: #ffff;
  width: 40px;
  height: 30px;
  border: 0;
  border-radius: 3px;
}
.login-icon {
  position: absolute;
  top: 15px;
  left: 28px;
  font-size: 15px;
}
.login-pswd-icon {
  position: absolute;
  top: 17px;
  right: 28px;
  font-size: 12px;
}
.login-form .form-control {
  padding-block: 22px;
  padding-inline: 40px;
}
.signup-form .form-control {
  padding-block: 22px;
}
.signup-pswd-icon {
  position: absolute;
  bottom: 36px;
  right: 28px;
  font-size: 12px;
}
.font-size-13 {
  font-size: 13px !important;
}
/*====================== 404 page =======================*/
.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
.dispatched-order-status {
  background: #edeff1;
  padding: 5px 9px;
  width: 100px;
  text-align: center;
  border-left: 3px solid #872ff7;
}
.dispatch-approved-order {
  border-left: 3px solid #872ff7;
}
.dispatch-reviewed-order {
  border-left: 3px solid #f9cf00;
}
.dispatch-revision-order {
  border-left: 3px solid #dc3545;
}
.border-modal-order {
  border-bottom: 1px solid #e9ecef;
}
.border-bottom-search {
  border-bottom: 1px solid #00000050;
  margin-left: 10px;
  margin-right: 10px;
}
.MuiBox-root {
  /* width: 100% !important; */
}

/* Login fields styling - start */
.login-field-violet,
.login-field-violet:-webkit-autofill,
.login-field-violet:-webkit-autofill:hover,
.login-field-violet:-webkit-autofill:focus,
.login-field-violet:-webkit-autofill:active {
  padding-inline: 40px;
  background-color: #872ff7 !important;
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #872ff7 inset !important;
  color: #fff !important;
  padding-block: 25px;
}

.login-field,
.login-field:-webkit-autofill,
.login-field:-webkit-autofill:hover,
.login-field:-webkit-autofill:focus,
.login-field:-webkit-autofill:active {
  padding-inline: 40px;
  background-color: #fff !important;
  -webkit-text-fill-color: #00000075 !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  color: #00000075 !important;
  padding-block: 25px;
}

.login-input-icons i,
.login-input-v-icons i {
  position: absolute;
}

.login-input-icons,
.login-input-v-icons {
  width: 100%;
  margin-bottom: 10px;
  text-align: start;
}

.login-icon {
  padding: 19px 12px;
  min-width: 40px;
}

.login-v-icon {
  padding: 19px 12px;
  min-width: 40px;
  color: #ffffff;
}

.eye-icon {
  width: 25px;
  height: 25px;
  z-index: 200;
  top: 35px;
  right: 20px;
  cursor: pointer;
}

/* To hide the default eye icon on password field */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.image-reset {
  margin-right: -180px;
  z-index: 1000;
}

@media (max-width: 1033px) {
  .image-reset {
    margin-right: -80px;
    z-index: 1000;
  }
}
/* Login fields styling - End */
.bg-grey {
  background: #d9d9d961;
}

.equipment-select .customselect {
  border: 1px solid #872ff759 !important;
  background: transparent !important;
  border-radius: 8px;
}
.equipment-select .customselectsearch {
  border-left: 2px solid #00000011 !important;
}
.equipment-select .custom-input-sleact {
  background: transparent;
  padding: 5px;
  border-left: none !important;
  color: #000 !important;
}
.equipment-select .css-14el2xx-placeholder {
  color: #000000;
}
.equipment-select .css-6j8wv5-Input {
  color: #000000;
}
.equipment-select .css-1gtu0rj-indicatorContainer {
  color: #872ff7 !important;
}

.equipment-select .custom-input-sleact::placeholder {
  color: #000000 !important;
}
.br-bl {
  border-bottom: 1px solid #00000015 !important;
}

equipment-master-ul {
}
.equipment-master-ul ul li {
  background: #00000010;
  padding: 8px 10px;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 #00000025;
  margin-top: 18px;
  overflow: hidden;
}
.equipment-master-ul li img {
  width: 65px;
}
.text-light-60 {
  color: #00000060;
}
.equipment-master-ul ul li.active {
  background: #ffffff;
}

.equipment-master-ul .text-truncate {
  max-width: 100px;
}

.global-serach-arrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 8px;
  top: 0;
  bottom: 0;
}

.scrollbar-repair-autocomplete {
  overflow-y: scroll;
  width: 100%;
  margin: 0;
}

.form-control-search {
  position: absolute;
  z-index: 2;
  display: block;
  width: 190%;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.form-control-ai-search {
  position: absolute;
  z-index: 2;
  display: block;
  width: 0%;
  padding-left: 12px;
  height: 2.375rem;
  /* line-height: 2.375rem; */
  line-height: 4rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.form-control-search-input input {
  /* width: 100%; */
  padding-left: 2.9rem;
}

.form-control-check-btn {
  position: absolute;
  z-index: 2;
  display: block;
  width: 180%;
  height: 3rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  /* color: #aaa; */
}

.form-control-check-btn button {
  padding: 7px 25px;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  cursor: pointer;
}

.width-75 {
  width: 75% !important;
}

.customerIdSearch .form-control-search span {
  color: #872ff7 !important;
}

.claim-requester-info img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
}

.sub-li {
  position: relative !important;
}

.sub-li:last-child::before {
  border: none;
}

.main-menu:hover .sub-li:before {
  border-left: none;
}

.active-span {
  color: #872ff7 !important;
  font-weight: 600;
}

.warranty-reject-btn {
  background-color: #7a7474b0 !important;
}

.warranty-reject-btn:hover {
  background-color: #872ff7 !important;
  /* color: #872ff7 !important; */
  /* border-color: #872ff7 !important; */
}

.return-btn {
  background-color: #ffffff !important;
  color: #872ff7 !important;
  border-color: #872ff7 !important;
  font-weight: 500;
}

.return-btn:hover {
  background-color: #872ff7 !important;
  color: #ffffff !important;
}

.btn-border-primary {
  border-color: #872ff7 !important;
  color: #872ff7 !important;
  background-color: #ffffff;
}

input[type="checkbox"]:checked:after {
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: #872ff7;
  text-align: center;
  line-height: 1.5em;
  border: 2px solid #872ff7;
  border-radius: 4px;
  margin-top: -3px;
  margin-left: -3px;
}
.checkbox input[type="checkbox"]:checked:after {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: #872ff7;
  text-align: center;
  line-height: 1.1em;
  border: 2px solid #872ff7;
  border-radius: 4px;
  margin-top: -2px;
  margin-left: -2px;
  font-size: 12px;
}

.claim-react-activity {
  background-color: #e0f9f5 !important;
}

.workflow-task {
  background: #e0f9f5 !important;
}

.file-upload-input {
  border: 1px solid #ced4da;
  padding: 7px 9px;
}

.warranty-req-dash-card {
  background: #e0f9f5 !important;
  /* color: #00bb98 !important; */
  border-color: #00bb98 !important;
}

.claim-req-dash-card {
  background: #e2b9e2b3 !important;
  /* color: #ff00ff42 !important; */
  border-color: #ff00ff42 !important;
}

.req-anlysis-dash-card {
  background: #ea4d141f !important;
  color: #ff4500d9 !important;
  border-color: #ee622f69 !important;
}
.req-replacement-dash-card {
  background: #99a6ff1f !important;
  color: #0013ff !important;
  border-color: #3e30eb69 !important;
}

.green-dot {
  width: 10px !important;
  height: 10px !important;
  background-color: green;
  border-radius: 50%;
}
.red-dot {
  width: 10px !important;
  height: 10px !important;
  background-color: red;
  border-radius: 50%;
}

.settlement-status {
  border: 1px solid #872ff7;
  background: #872ff7;
  border-radius: 10px;
  color: #ffffff;
}
.price-info-settlement {
  /* border: 1px solid green; */
  border-radius: 50px;
  margin-top: 5px;
  background-color: #e6f8f5;
  color: #02b894;
  font-size: 12px;
}

.settlement-price-dot {
  height: 10px;
  width: 10px;
  background-color: #02b894;
  border-radius: 50px;
}
.optionl-service-input {
  border: 1px solid #ced4da;
  background-color: #e9ecef;
  /* padding: 7px 9px; */
}

.font-size-28 {
  font-size: 28px !important;
}

.attachment-dropdown {
  padding: 10px 25px !important;
}
.order-for-parts-equ:hover {
  border: 1px solid #8fbbfd !important;
  color: #8fbbfd !important;
  box-shadow: 1px 2px 2px 0px #8fbbfd !important;
  font-weight: 700;
}

.create-parts-api-working {
  background-color: #f2f2f2;
}

.create-parts-api-working .form-control {
  background-color: #e9ecef;
}
.create-parts-api-working .form-controldate {
  background-color: #e9ecef;
}

.bg-blue-white-gradient {
  background: linear-gradient(90deg, #6a5acd, #1e90ff);
  background-clip: text;
  color: transparent;
}

.register-products-card:hover {
  background-color: #872ff7 !important;
  color: white !important;
  box-shadow: 1px 2px 2px 0px #872ff7 !important;
  border: 1px solid #872ff7 !important;
}

.register-products-active-card {
  background-color: #872ff7 !important;
  color: white !important;
  box-shadow: 1px 2px 2px 0px #872ff7 !important;
  border: 1px solid #872ff7 !important;
}
