
.search-box {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  align-items: center;
  margin-right: 20px;
  gap: 10px;
  justify-content: center;
  align-self: flex-start;
}

.custom-select {
  background: #f8f9fd;
  border: none;
  border-radius: 0px 5px 5px 0;
  color: hsl(0deg 0% 19%);
  border-left: 1px solid #872ff7 !important;
  padding-left: 5px;
  width: 8rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: #f8f8f8;
  font-size: 14px;
  height: calc(2.0625rem + 6px);
}

.modal-footer-guided {
  margin-block: 50px;
  display: flex;
  justify-content:space-around;
  align-items: center;
  padding: 1rem;
  /* border-top: 1px solid #e9ecef; */
  color: #76838f;
  flex-wrap: wrap;

}
/* .modal-footer-guided > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer-guided > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-footer-guided > :last-child {
  margin-left: auto;
} */

.and-or-dpdown{
  border: 1.75px solid #872ff7;
  border-radius: 6px;
}

.second-line{
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
  margin-right: 12px;
  margin-bottom: 1rem;
}

.search-button{
  background-color: #872ff7!important;
  justify-self: center;
  align-self: end;
  width: fit-content;
}


